import { LocaleConfigItem } from 'fitify-ui-landing/src/@types/ConfigTypes'
import { useRouter } from 'next/router'
import { LinkTag } from 'next-seo/lib/types'
import { useMemo } from 'react'

import localeConfig from '../../config/localeConfig.json'

/**
 * This hook returns alternative language link tags for SEO purposes.
 * For details see https://developers.google.com/search/docs/specialty/international/localized-versions?hl=en#html
 */
export const useLocalizationLinkTags = (): LinkTag[] => {
  const router = useRouter()
  const supportedLocales: LocaleConfigItem[] = localeConfig

  return useMemo(
    () =>
      supportedLocales.map((locale) => {
        return {
          rel: 'alternate',
          hrefLang: locale.origin,
          href:
            locale.origin !== 'en'
              ? `/${locale.origin}${router.asPath}`
              : `${router.asPath}`,
        }
      }),
    [router.asPath, supportedLocales]
  )
}
