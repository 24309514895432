import { useDeviceSize } from 'fitify-ui'
import {
  MobileAnimation,
  MobileAppFeatures,
} from 'fitify-ui-landing/src/components/blocks'
import Header from 'fitify-ui-landing/src/components/blocks/Header/Header'
import Mentions from 'fitify-ui-landing/src/components/blocks/Mentions/Mentions'
import Reviews from 'fitify-ui-landing/src/components/blocks/Reviews/Reviews'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import { size } from 'fitify-ui-landing/src/theme/breakpoints'
import { createRedirectLinkWithLocale } from 'fitify-ui-landing/src/utils/link'
import { NextPage } from 'next'
import { useTranslation } from 'next-i18next'

import Apps from '@/components/blocks/Apps/Apps'
import Tools from '@/components/blocks/Tools/Tools'
import Layout from '@/components/layouts/Layout'
import { animationData } from '@/data/animations'
import { headerImages } from '@/data/header'
import { mentionsData } from '@/data/mentions'
import { reviewsData } from '@/data/reviews'
import { useLocalizationLinkTags } from '@/hooks/useLocalizationLinkTags'
import { getStaticPropsTranslations } from '@/utils/i18n'

const Home: NextPage = () => {
  const { t, i18n } = useTranslation()

  const { windowWidth } = useDeviceSize()
  const isPhoneBreakpoint = windowWidth <= size.phone
  const localizationLinkTags = useLocalizationLinkTags()

  return (
    <Layout seo={{ additionalLinkTags: localizationLinkTags }}>
      <Header
        headerImages={headerImages}
        headline={'landing_page_title'}
        subheadline={'landing_page_subheadline'}
        variant="header-title"
        button={
          <Button
            icon={'arrow-right'}
            variant={'primary-blue'}
            href={createRedirectLinkWithLocale(
              process.env.NEXT_PUBLIC_ONBOARDING_URL,
              i18n.language
            )}
            target="_blank"
          >
            {t('landing_cta_button')}
          </Button>
        }
        shouldShowRating
      />

      {isPhoneBreakpoint ? (
        <MobileAppFeatures features={animationData} />
      ) : (
        <MobileAnimation features={animationData} />
      )}

      <Mentions mentionsItems={mentionsData} />

      <Reviews
        headline={'landing_review_block_headline'}
        items={reviewsData}
        isBusiness={false}
      />

      <Tools />
      <Apps />
    </Layout>
  )
}

export default Home

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await getStaticPropsTranslations(locale)),
    },
  }
}
